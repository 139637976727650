<template>
  <form
    class="border rounded-md shadow-md"
    :class="focused && 'border-primary'"
    data-cy="log-quick-submit"
    @submit.prevent="onSubmit"
  >
    <textarea
      v-model="text"
      class="w-full border-gray-200 placeholder-gray-400 rounded text-sm text-gray-600 resize-none"
      :placeholder="placeholder"
      :rows="2"
      @focus="focused = true"
      @blur="focused = false"
      @keydown.enter="onSubmit"
    />
    <slot name="supplemental" />
    <div
      class="bg-grey-hover flex items-center justify-between relative rounded-b-md"
      :class="[$slots.supplemental ? 'px-3 pt-1 pb-3' : 'p-3']"
    >
      <slot name="actionBar"></slot>

      <div class="flex justify-end space-x-3 items-center w-7/10">
        <slot name="buttons">
          <div class="space-x-1">
            <span class="font-bold">{{ $t("COMMON.RETURN") }}</span>
            <span v-if="returnPlaceholder">
              {{ returnPlaceholder }}
            </span>
          </div>
          <button
            type="submit"
            :disabled="isDisabled"
            data-cy="log-quick-submit-button"
            class="h-8 w-8 flex items-center justify-center rounded"
            :class="
              isDisabled
                ? ' bg-gray-300 cursor-not-allowed'
                : 'bg-primary cursor-pointer'
            "
          >
            <loader :is-loading="loading" />
            <icon-base :icon="IconSend" />
          </button>
        </slot>
      </div>
    </div>
  </form>
</template>

<script setup lang="ts">
import IconSend from "@/components/icons/IconSend.vue";
import { computed, ref } from "vue";

const props = defineProps({
  loading: {
    type: Boolean
  },
  placeholder: {
    type: String,
    default: ""
  },
  returnPlaceholder: {
    type: String,
    default: ""
  },
  modelValue: {
    type: String,
    required: true
  },
  fieldName: {
    type: String,
    default: ""
  },
  textRequired: {
    type: Boolean,
    default: true
  }
});
const emit = defineEmits<{
  "update:modelValue": [value: string];
  submit: [];
}>();

const focused = ref(false);

const text = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value)
});

const isDisabled = computed(
  () => (props.textRequired && !text.value.trim()) || Boolean(props.loading)
);

const onSubmit = (event: Event) => {
  if ((event as KeyboardEvent).shiftKey || isDisabled.value) {
    return;
  }
  emit("submit");
};
</script>

<style scoped>
textarea {
  border: none;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
</style>
